import React from "react";
import * as styles from "./404.module.scss";

const NotFound = () => (
  <div className={styles.notFound}>
    <h1>NOT FOUND</h1>
    <div>
      <p>The page you are looking for does not exist</p>
    </div>
  </div>
);

export default NotFound;
